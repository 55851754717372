export const environment = {
  apiUrl: 'https://api.qa.hostize.com',
  uiMode: 'qa',
  uiHost: 'https://qa.hostize.com',
  chatizeUiHost: 'https://products-qa.chatize.com',
  keycloakConfig: {
    url: 'https://id.containerize.app',
    realm: 'hostize-com',
    clientId: 'hostize-com-qa',
  },
  languages: [
    'en',
    'es',
    'fr',
    'id',
    'pt',
    'ru',
    'tr',
    'zh',
  ],
}
